import axios from "axios";
import { reportError } from "@/utils/error-reporting";

const API = process.env.VUE_APP_API_URL.replace(/\/$/, "");
const imageAPI = `${API}/api/common/file-uploader/image`;

export async function uploadImage(imageFile) {
    try {
        const form = new FormData();
        form.append("file", imageFile);
        const request = await axios.post(`${imageAPI}`, form);
        return request.data;
    } catch (error) {
        reportError(error, "Upload Image");
        throw error;
    }
}
export async function uploadWatermarkImage(imageFile) {
    try {
        const form = new FormData();
        form.append("file", imageFile);
        form.append("watermark", true);
        /*const request = await axios.post(`${imageAPI}`, form,
	headers:{"Accept":"application/json, text/plain, /","Content-Type": "multipart/form-data"}
	);*/
	const request = await axios({
      url: `${imageAPI}`,
      headers:{"Accept":"application/json, text/plain, /","Content-Type": "multipart/form-data"},
      data: form,
      method: 'POST'
    });
	return request.data;
    } catch (error) {
	console.log("er1",error);;
	    console.log("err2",error.response);
	    console.logg("err3",error.data);
	    console.log("eeee",error.response.data);
        reportError(error, "Upload Watermark Image");
        throw error;
    }
}

export default { uploadImage, uploadWatermarkImage };
